/**
 * Enum for user role
 * @readonly
 * @enum {string}
 */
export default {
  Player: "player",
  Host: "facilitator",
  Audit: "audit",
  Spectator: "spectator",
  Super: "super"
}
