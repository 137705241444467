import Vue from "vue"

import "@/services/tournament/typedef"
import { TournamentService } from "@/services/tournament/tournament.service"

export const MODULE_NAME = "tournament"

export const MutationTypes = {
  SET_TOURNAMENTS: "SET_TOURNAMENTS",
  DELETE_TOURNAMENT: "DELETE_TOURNAMENT",
  SHOW_TOURNAMENT_STANDING: "SHOW_TOURNAMENT_STANDING"
}

export const ACTION_TYPES = {
  SHOW_TOURNAMENT_STANDING: "SHOW_TOURNAMENT_STANDING",
  GET_TOURNAMENTS: "GET_TOURNAMENTS",
  GET_ROUND_GAMES: "GET_ROUND_GAMES",
  GET_NEW_TOURNAMENT_ID: "GET_NEW_TOURNAMENT_ID",
  UPDATE_TOURNAMENT: "UPDATE_TOURNAMENT",
  DELETE_TOURNAMENT: "DELETE_TOURNAMENT"
}

/** @type import('vuex').Module<{ tournaments: Record<string, Tournament>  }, any> */
const TournamentModule = {
  namespaced: true,
  state: {
    /** @type {Record<string, Tournament>} */
    tournaments: [],
    standing: null
  },
  mutations: {
    /**
     * @param {Record<string, Tournament>} tournaments
     */
    [MutationTypes.SET_TOURNAMENTS](state, tournaments) {
      state.tournaments = tournaments
    },
    /**
     * @param {string} id
     */
    [MutationTypes.DELETE_TOURNAMENT](state, id) {
      Vue.delete(state.tournaments, id)
    },
    [MutationTypes.SHOW_TOURNAMENT_STANDING](state, payload) {
      state.standing = payload
    }
  },
  getters: {
    showTournamentStanding: state => !!state.standing
  },
  actions: {
    /**
     * @param { { clientID: string } } payload
     */
    [ACTION_TYPES.SHOW_TOURNAMENT_STANDING]({ commit }, payload) {
      commit(MutationTypes.SHOW_TOURNAMENT_STANDING, payload)
    },
    async [ACTION_TYPES.GET_TOURNAMENTS]({ commit }, payload) {
      try {
        const tournaments = await TournamentService.fetchTournamentsByClientID(
          payload.clientID
        )
        commit(MutationTypes.SET_TOURNAMENTS, tournaments)
        return tournaments
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * @param { { tournamentID: string, roundID: string } } payload
     */
    async [ACTION_TYPES.GET_ROUND_GAMES]({ rootGetters }, payload) {
      return TournamentService.getRoundGames(
        rootGetters.orgID,
        payload.tournamentID,
        payload.roundID
      )
    },
    /**
     * @param { { clientID: string } } payload
     */
    async [ACTION_TYPES.GET_NEW_TOURNAMENT_ID](_, payload) {
      return TournamentService.getNewID(payload.clientID)
    },
    /**
     * @param { { clientID: string, tournamentID: string, value: Tournament } } payload
     */
    async [ACTION_TYPES.UPDATE_TOURNAMENT]({ commit }, payload) {
      const { clientID, tournamentID, value } = payload
      const res = await TournamentService.updateTournamentsByClientID(
        clientID,
        tournamentID,
        value
      )
    },
    /**
     * @param { { clientID: string, tournamentID: string } } payload
     */
    async [ACTION_TYPES.DELETE_TOURNAMENT]({ commit }, payload) {
      try {
        const res = await TournamentService.deleteTournament(
          payload.clientID,
          payload.tournamentID,
          payload.orgID
        )
        commit(MutationTypes.DELETE_TOURNAMENT, payload.tournamentID)
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export default TournamentModule
