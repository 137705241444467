function getKeynoteKey(theKey) {
  return `viewedKeynote-${theKey}`
}

export function getKeynoteVisited(theKey) {
  const key = getKeynoteKey(theKey)
  return JSON.parse(localStorage.getItem(key) || "false")
}

export function setKeynoteVisited(theKey) {
  const key = getKeynoteKey(theKey)
  // remove previous data
  removeItemsByPatterns([/viewedKeynote-/])
  localStorage.setItem(key, "true")
}

export function removeItemsByPatterns(patterns) {
  patterns = Array.isArray(patterns) ? patterns : [patterns]
  Object.keys(localStorage).forEach(key => {
    for (const pattern of patterns) {
      if (pattern.test(key)) {
        localStorage.removeItem(key)
        return
      }
    }
  })
}
