export class Client {
  static isImagesValid(images, count = 3) {
    if (Array.isArray(images)) {
      return Client.getNormalizedImages(images).length >= count
    }

    return false
  }

  static getNormalizedImages(images) {
    return images.filter(image => typeof image === "string" && image !== "")
  }
}
