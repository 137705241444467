<template>
  <div class="login-form">
    <p class="login-form__title">
      {{ enterText }}
    </p>

    <v-form @submit.prevent="onSignin" ref="form">
      <p>Email</p>
      <v-text-field
        type="text"
        :rules="[rules.required, rules.email]"
        required
        placeholder="Email"
        v-model="email"
      ></v-text-field>
      <v-flex d-flex>
        <p>Password</p>
        <div
          class="form__secondary-button text-xs-right"
          @click="setForgotPassword()"
        >
          Forgot password?
        </div>
      </v-flex>
      <v-text-field
        type="password"
        :rules="[rules.required]"
        required
        placeholder="********"
        v-model="password"
      />

      <div class="login-form__action-container">
        <input
          class="login-form__submit"
          type="submit"
          :disabled="!isFormValid"
          value="Log in"
        />
        <div class="login-form__or">OR</div>
        <GoogleButton
          class="login-form__google-button"
          @click="signInWithGoogle"
        />
      </div>

      <div class="terms-and-conditions mt-2">
        By logging in you agree to our
        <a @click.stop :href="privacyLink" target="_blank">Terms & Conditions</a
        >.
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { Navigation } from "@/helpers"
import HubSpot from "@/services/hubspot.service"

import GoogleButton from "@/components/GoogleButton.vue"
import LoginMixin from "@/components/User/LoginAndOnboarding/mixins/LoginMixin"

const PRIVACY_LINK = "https://www.thegogame.com/team-building/privacy-policy/"

export default {
  name: "EmailForm",
  mixins: [LoginMixin],
  components: {
    GoogleButton
  },
  data() {
    return {
      email: "",
      password: "",
      privacyLink: PRIVACY_LINK,
      isFormValid: false,
      rules: {
        required: value => !!value || "Field is required",
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Invalid e-mail."
        }
      }
    }
  },

  props: ["id", "setForgotPassword", "setRegister", "enterLoginText"],

  computed: {
    enterText() {
      if (this.enterLoginText) {
        return this.enterLoginText
      } else {
        return "Tell us who you are!"
      }
    },
    identifier() {
      return this.customInput
        ? this.customInput
        : this.customInputType &&
          this.customInputOptions &&
          this.customInputOptions.length
        ? // default to the last item on the list
          this.customInputOptions[this.customInputOptions.length - 1]
        : null
    },

    formContent() {
      return `${this.email}|${this.password}`
    }
  },

  watch: {
    formContent(val, prevVal) {
      if (val !== prevVal) {
        if (this.$refs.form.validate()) {
          this.isFormValid = true
        } else {
          this.isFormValid = false
        }
      }
    }
  },

  created() {
    const { audit } = this.$route.query

    if (audit === "1") {
      this.audit = true
    } else if (audit === "0") {
      this.audit = false
    }
  },

  methods: {
    ...mapActions("auth", ["signUserIn", "signGoogleUserIn"]),

    async signInWithGoogle() {
      HubSpot.push([
        "trackCustomBehavioralEvent",
        {
          name: "pe2803895_sign_in",
          properties: {
            account_type: "google"
          }
        }
      ])

      this.$_LoginMixin_maybeTriggerHubspotDemoEvent("google")

      HubSpot.track()

      await this.signGoogleUserIn({
        audit: this.audit,
        ...Navigation.parseUrlID(this.id)
      })
    },

    async onSignin() {
      HubSpot.push([
        "trackCustomBehavioralEvent",
        {
          name: "pe2803895_sign_in",
          properties: {
            account_type: "regular"
          }
        }
      ])

      this.$_LoginMixin_maybeTriggerHubspotDemoEvent("regular")

      HubSpot.track()

      await this.signUserIn({
        email: this.email,
        password: this.password,
        audit: this.audit,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })

      const { next } = this.$route.query
    }
  }
}
</script>

<style lang="scss">
.login-form {
  &__action-container {
    display: flex;
    flex-direction: column;
  }
  &__or {
    text-align: center;
    padding-top: min(max(10px, 1.5vh), 30px);
    padding-bottom: min(max(10px, 1.5vh), 30px);
    font-size: 16px;
    color: #586475;
  }
}
</style>
