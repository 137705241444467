export class SelectManager {
  /**
   * @param {Record<string, any>} object
   * @param {string} textKey
   */
  static normalize(object, textKey) {
    const array = []

    for (const key in object) {
      array.push({ value: key, text: object[key][textKey] })
    }

    return array
  }
}
