const MAP = {
  Pictionary: "missions/pictionary",
  Lipdub: "missions/lipdub",
  Trivia: "missions/multiple-choice",
  "Face Off": "missions/player-rating",
  "Creative Drawing": "missions/pictionary",
  "Fun Fact Match": "missions/fact-match",
  Categories: "missions/categories"
}

export class Mission {
  /**
   * @param {string} type
   */
  static missionTypeToIcon(type) {
    return MAP[type]
  }
}
