export const MediaMixin = {
  methods: {
    /**
     * Determines if facilitator should be able to hear from
     * a media or not.
     * There are different component controlling the audio of a user.
     * A team, user, and facilitator audio can be muted.
     * So if a team a user belongs to is muted, then the user should
     * not be able to hear from that team irrespctive if the user
     * audio is muted or not. Here, team takes higher precedence.
     *
     * @param {Object} team - The team payload
     * @param {Object} user - The user payload
     */
    isAudioMuted(team, user) {
      if (team.muted) return true

      if (user.muted) return true

      return false
    }
  }
}
