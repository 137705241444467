<script>
const MAX_LENGTH = 10
const OFFSET = 3
export default {
  name: "UserName",
  functional: true,
  props: {
    firstname: String,
    lastname: String
  },
  render(h, { _v, props }) {
    let name = props.firstname || ""
    if (name.length > MAX_LENGTH) {
      name = name.substr(0, MAX_LENGTH)
    } else if (props.lastname && name.length + OFFSET <= MAX_LENGTH) {
      name = name + " " + props.lastname.charAt(0) + "."
    }
    return _v(name)
  }
}
</script>
