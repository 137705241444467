const parseUrlID = id => {
  if (id && id.length > 20) {
    const clientID = id.substr(0, 20)
    const gameID = id.substr(20)
    return { clientID, gameID }
  } else if (id) {
    return { clientID: id, gameID: null }
  } else {
    return { clientID: null, gameID: null }
  }
}

const createUrlID = ({ clientID, gameID }) => {
  if (clientID && gameID) {
    return `${clientID}${gameID}`
  } else if (clientID) {
    return clientID
  } else {
    return ""
  }
}

export default { parseUrlID, createUrlID }
