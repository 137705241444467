/**
 * @param {HTMLElement} parent
 * @param {HTMLElement} child
 */
export function getRectRelativeToParent(parent, child) {
  const parentRect = parent.getBoundingClientRect()
  const childRect = child.getBoundingClientRect()

  return {
    width: childRect.width,
    height: childRect.height,
    top: childRect.top - parentRect.top,
    right: childRect.right - parentRect.right,
    bottom: childRect.bottom - parentRect.bottom,
    left: childRect.left - parentRect.left
  }
}
