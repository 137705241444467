export class GameHistoryStorage {
  /**
   * @param {string} gameID
   * @returns {bolean}
   */
  static add(gameID) {
    try {
      const value = localStorage.getItem(GameHistoryStorage.LOCAL_STORAGE_KEY)

      if (value === null) {
        localStorage.setItem(
          GameHistoryStorage.LOCAL_STORAGE_KEY,
          JSON.stringify([gameID])
        )
      } else {
        const parsed = JSON.parse(value)

        if (Array.isArray(parsed)) {
          if (parsed.includes(gameID) === false) {
            parsed.push(gameID)
            localStorage.setItem(
              GameHistoryStorage.LOCAL_STORAGE_KEY,
              JSON.stringify(parsed)
            )
          }
        } else {
          localStorage.setItem(
            GameHistoryStorage.LOCAL_STORAGE_KEY,
            JSON.stringify([gameID])
          )
        }
      }

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @param {string} gameID
   * @returns {boolean}
   */
  static has(gameID) {
    try {
      const value = localStorage.getItem(GameHistoryStorage.LOCAL_STORAGE_KEY)

      if (value === null) {
        return false
      } else {
        const parsed = JSON.parse(value)

        if (Array.isArray(parsed)) {
          return parsed.includes(gameID)
        } else {
          return false
        }
      }
    } catch (e) {
      console.log(e)
      return true
    }
  }

  static LOCAL_STORAGE_KEY = "PLAYED_GAMES"
}
