/** @typedef {import('../types').Styles} Styles */
export class StyleManager {
  /** @param {Styles} styles */
  static set(styles) {
    for (const property in styles) {
      document.documentElement.style.setProperty(property, styles[property])
    }
  }

  /** @param {string[]} properties */
  static clean(properties) {
    properties.forEach(property =>
      document.documentElement.style.removeProperty(property)
    )
  }
}
